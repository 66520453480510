import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

const Admin = () => {
  const { auth } = useContext(AuthContext);

  return (
    <div>
      <h2>Admin Page</h2>
      <p>Welcome, {auth.username}!</p>
      {/* Add admin functionalities here */}
    </div>
  );
};

export default Admin;
