import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from './Carousel';
import Login from './Login';
import Admin from './Admin';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './AuthContext';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/admin" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
          <Route path="/ODISEO-CASINO-P0" element={<CarouselPage categoria="ODISEO,CASINO-P0" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-CLUB-P1" element={<CarouselPage categoria="ODISEO,CLUB-P0" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-RESTAURANTE-P2" element={<CarouselPage categoria="ODISEO,RESTAURANTE-P2" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-COLLADOS" element={<CarouselPage categoria="COLLADOS" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-EVENTO" element={<CarouselPage categoria="EVENTO" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-RINCON-PEPE" element={<CarouselPage categoria="RINCON-PEPE" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-SIM-RACING" element={<CarouselPage categoria="SIM-RACING" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-FINCA-BUENAVISTA" element={<CarouselPage categoria="FINCA-BUENAVISTA" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/ODISEO-MERCADO" element={<CarouselPage categoria="MERCADO" tableName="bdImagenesOdiseoV2" />} />
          <Route path="/TV-ESP" element={<CarouselPage categoria="VERSUS-ESP" tableName="bdImagenesVERSUS" />} />
          <Route path="/TV-IE" element={<CarouselPage categoria="VERSUS-IRL" tableName="bdImagenesVERSUS" />} />
          <Route path="/TOTEM-IE" element={<CarouselPage categoria="VERSUS-IRL-TOTEM" tableName="bdImagenesVERSUS" />} />
          
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const CarouselPage = ({ categoria, tableName }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    /* const apiUrl = "http://localhost:8080"; */
    fetch(`${apiUrl}/api/carousel-items?categoria=${categoria}&tableName=${tableName}`)
      .then(response => response.json())
      .then(data => setItems(data));

      /* const socket = new WebSocket(`${apiUrl.replace(/^https/, 'wss')}`);
      socket.onmessage = (event) => {
        const newItems = JSON.parse(event.data);
        console.log(newItems);
        setItems(newItems);
      };

    return () => socket.close(); */
  }, [categoria, tableName]);

  return <Carousel items={items} />;
};

export default App;
