import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';

const baseUrl = 'https://orenesgrancasino.com/totem-odiseo/administrador/php/archivos/';

const Carousel = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);

  const handleSelect = useCallback((selectedIndex) => {
    setActiveIndex(selectedIndex);
  }, []);

  const clearExistingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    const preloadMedia = async () => {
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      };

      const loadVideo = (src) => {
        return new Promise((resolve, reject) => {
          const video = document.createElement('video');
          video.src = src;
          video.onloadeddata = resolve;
          video.onerror = reject;
        });
      };

      const preloadPromises = items.map(item => {
        const src = `${baseUrl}${item.imagen}`;
        return item.tipo === 'video' ? loadVideo(src) : loadImage(src);
      });

      try {
        await Promise.all(preloadPromises);
        setIsLoading(false);
      } catch (error) {
        console.error('Error preloading media:', error);
      }
    };

    preloadMedia();
  }, [items]);

  useEffect(() => {
    const activeItem = items[activeIndex];
    var videoElement;
    if (activeItem && activeItem.tipo === 'video') {
      videoElement = document.getElementById(`video-${activeIndex}`);

      if (videoElement) {
        videoElement.onended = () => {
          const nextIndex = (activeIndex + 1) % items.length;
          setActiveIndex(nextIndex);
        };
      }
    } else {
      clearExistingInterval();
      intervalRef.current = setInterval(() => {
        const nextIndex = (activeIndex + 1) % items.length;
        setActiveIndex(nextIndex);
      }, 10000); // Show images for 10 seconds
    }

    return () => {
      if (activeItem && activeItem.tipo === 'video' && videoElement) {
        videoElement.onended = null;
      }
      clearExistingInterval();
    };
  }, [activeIndex, items]);

  const handleVideoError = () => {
    console.error(`Error loading video ${activeIndex}`);
    // Skip to the next item
    const nextIndex = (activeIndex + 1) % items.length;
    setActiveIndex(nextIndex);
  };

  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a loader component if needed
  }

  return (
    <BootstrapCarousel
      activeIndex={activeIndex}
      onSelect={handleSelect}
      controls={false}
      interval={null} // We handle the interval manually
      ref={carouselRef}
      indicators={false} // Hide the indicators
    >
      {items.map((item, index) => (
        <BootstrapCarousel.Item key={index}>
          {item.tipo === 'video' ? (
            <video
              id={`video-${index}`}
              src={`${baseUrl}${item.imagen}`}
              loop={false}
              muted
              autoPlay
              preload="auto"
              style={{ width: '100vw', height: '100vh' }}
              onError={handleVideoError}
            />
          ) : (
            <img
              src={`${baseUrl}${item.imagen}`}
              alt=""
              style={{ width: '100vw', height: '100vh' }}
              loading="lazy"
            />
          )}
        </BootstrapCarousel.Item>
      ))}
    </BootstrapCarousel>
  );
};

export default React.memo(Carousel);
